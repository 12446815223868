import Navbar from "../../../components/navbar/navbar";
import { Container } from "./style";
import { useState } from "react";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import ProfileSidenav, {
    ProfileSidenavPage,
} from "../../../components/profile-sidenav/profileSidenav";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { useParams } from "react-router-dom";
import MySpaceEngineerProfile from "./my-space-engineer-profile";
import PropertiesEngineer from "../../propertiesEngineer/propertiesEngineer";
import "./menu.css";
import MySpaceEngineerContracts from "./my-space-engineer-contracts";

export default function MySpaceEngineer() {
    const { subpage } = useParams();
    const { t } = useTranslation();
    const [activePage, setActivePage] = useState(subpage || "profile");
    const [activeComponent, setActiveComponent] = useState("MySpaceBank");

    const renderComponent = () => {
        switch (activeComponent) {
            case "MySpaceProfile":
                return <MySpaceEngineerProfile />;
            case "MySpaceProperty":
                return <PropertiesEngineer />;
            case "Contracts":
                return <MySpaceEngineerContracts />;
            default:
                return <MySpaceEngineerProfile />;
        }
    };

    const renderTitle = () => {
        switch (activeComponent) {
            case "MySpaceProfile":
                return t("profile");
            case "MySpaceProperty":
                return t("my-properties");
            case "Contracts":
                return t("contracts");
            default:
                return t("profile");
        }
    };

    return (
        <Container>
            <Navbar />
            <Breadcrumb itens={[t("my-space"), t(activePage)]} />

            <div className="container">
                <div className="bloco-menu">
                    <ul className="vertical-menu">
                        <li>
                            <a
                                href="#!"
                                onClick={() =>
                                    setActiveComponent("MySpaceProperty")
                                }
                            >
                                {t("my-properties")}
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() =>
                                    setActiveComponent("MySpaceProfile")
                                }
                            >
                                {t("profile")}
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() => setActiveComponent("Contracts")}
                            >
                                {t("contracts")}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="bloco-corpo">
                    <h1>{renderTitle()}</h1>
                    {renderComponent()}
                </div>
            </div>

            <Footer />
        </Container>
    );
}
