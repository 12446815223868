// AuthContext.tsx
import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
} from "react";
import axios, { AxiosRequestConfig } from "axios";
import {
    apiBase,
    baseEngineer,
    baseBuyer,
    baseSeller,
} from "../utils/server/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IUser } from "../models/User";
import { LoginApiResponse } from "../models/LoginResponse";
import { useTranslation } from "react-i18next";

interface AuthContextProps {
    children: ReactNode;
}

interface AuthContextType {
    isAuthenticated: boolean;
    login: (data: any) => void;
    loginSeller: (data: any) => void;
    loginEngineer: (data: any) => void;
    register: (data: any) => void;
    registerSeller: (data: any) => void;
    registerEngineer: (data: any) => void;
    forgetPassword: (data: any) => void;
    forgetPasswordSeller: (data: any) => void;
    forgetPasswordEngineer: (data: any) => void;
    logout: () => void;
    axiosConfig: () => AxiosRequestConfig<any>;
    headerConfig: () => any;
    me: () => void;
    user: IUser | null;
    warning: string;
    registerError: any;
}

const axiosConfig = (
    data: AxiosRequestConfig<any> = {}
): AxiosRequestConfig<any> => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
    },
    ...data,
});

const headerConfig = (data: any = {}): any => ({
    headers: {
        "Content-Type": "application/json",
    },
    ...data,
});

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<IUser | null>(null);
    const [registerError, setRegisterError] = useState([]);
    const [warning, setWarning] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        const checkAuthStatus = () => {
            const token = localStorage.getItem("token");
            setIsAuthenticated(!!token);
        };

        checkAuthStatus();
    }, [user]);

    useEffect(() => {
        if ((localStorage.getItem("token") || "").length > 0) {
            me();
        }
    }, []);

    const login = async (data: {
        email: string;
        password: string;
        user_type: string;
    }) => {
        try {
            const response = await axios.post(`${apiBase}/login`, data);
            const loginResponse = response.data as LoginApiResponse;

            localStorage.setItem("token", loginResponse.authorization.token);
            localStorage.setItem("userType", loginResponse.user.user_type);
            localStorage.setItem("userId", String(loginResponse.user.id));
            localStorage.setItem("user", JSON.stringify(loginResponse.user));

            setUser(() => ({ ...loginResponse.user }));

            switch (loginResponse.user.user_type) {
                case "seller":
                    navigate("/homeBuy");
                    break;
                case "engineer":
                    navigate("/homeBuy");
                    break;
                case "buyer":
                    navigate("/homeBuy");
                    break;
                case "satelite":
                    navigate("/homeBuy");
                    break;
            }
        } catch (error) {
            toast.error(t("error-login"), {
                position: toast.POSITION.TOP_CENTER,
            });
            console.error("Login error:", error);
        }
    };

    const loginSeller = async (data: any) => {
        try {
            const response = await axios.post(`${baseSeller}/login`, {
                ...data,
                grant_type: "user_credentials",
                client_id: "android",
                client_secret: "testpass",
            });

            toast.success("Sucesso", {
                position: toast.POSITION.TOP_CENTER,
            });

            localStorage.setItem("token", response.data.access_token);

            if (response.data.result === false) {
                toast.error(t("error-login"), {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                navigate("/homeBuy");
            }
        } catch (error) {
            toast.error("Usuário ou senha inválidos", {
                position: toast.POSITION.TOP_CENTER,
            });
            console.error("Login error:", (error as Error).message);
        }
    };

    const loginEngineer = async (data: any) => {
        try {
            const response = await axios.post(`${baseEngineer}/login`, {
                ...data,
                grant_type: "user_credentials",
                client_id: "android",
                client_secret: "testpass",
            });

            toast.success("Sucesso", {
                position: toast.POSITION.TOP_CENTER,
            });

            localStorage.setItem("token", response.data.access_token);

            if (response.data.result === false) {
                toast.error(t("error-login"), {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                navigate("/homeBuy");
            }
        } catch (error) {
            toast.error("Usuário ou senha inválidos", {
                position: toast.POSITION.TOP_CENTER,
            });
            console.error("Login error:", (error as Error).message);
        }
    };

    const register = async (data: any) => {
        try {
            const response = await axios.post(`${baseBuyer}/signup`, {
                ...data,
            });

            if (response.data.errors && Array.isArray(response.data.errors)) {
                console.log(response.data.errors);
                setRegisterError(response.data.errors);
            } else {
                console.log("Nenhum erro encontrado na resposta");
            }

            if (response.data.result === false) {
                toast.error(t("error-login"), {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                navigate("/loginBuy");
            }
        } catch (error) {
            toast.error(t("error-login"), {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const registerSeller = async (data: any) => {
        try {
            const response = await axios.post(`${baseSeller}/signup`, {
                ...data,
            });

            if (response.data.errors && Array.isArray(response.data.errors)) {
                console.log(response.data.errors);
                setRegisterError(response.data.errors);
            } else {
                console.log("Nenhum erro encontrado na resposta");
            }

            if (response.data.result === false) {
                toast.error(t("error-login"), {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                navigate("/loginSeller");
            }
        } catch (error) {
            toast.error(t("error-login"), {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const registerEngineer = async (data: any) => {
        try {
            const response = await axios.post(`${baseEngineer}/signup`, {
                ...data,
            });

            if (response.data.errors && Array.isArray(response.data.errors)) {
                console.log(response.data.errors);
                setRegisterError(response.data.errors);
            } else {
                console.log("Nenhum erro encontrado na resposta");
            }

            if (response.data.result === false) {
                toast.error(t("error-login"), {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                navigate("/loginengineer");
            }
            // navigate("/loginSeller");
        } catch (error) {
            toast.error(t("error-login"), {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const me = async () => {
        try {
            const response = await axios.get(`${apiBase}/user`, axiosConfig());
            if (response.status < 200 || response.status > 299) {
                navigate("/choice");
                return;
            }
            setUser(() => ({ ...response.data }));
            return user;
        } catch (error) {
            console.error("Error on user retrieval", error);
            logout();
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userType");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        await navigate("/choice");
    };

    const forgetPassword = async (data: any) => {
        try {
            const response = await axios.post(`${baseBuyer}/forgot-password`, {
                data,
                config: axiosConfig(),
            });
            if (response.data.result === false) {
                toast.error("Erro", {
                    position: toast.POSITION.TOP_CENTER,
                });
                setWarning(response.data.msg);
            } else {
                toast.success("Sucesso", {
                    position: toast.POSITION.TOP_CENTER,
                });
                setWarning(
                    "Verifique sua caixa de entrada para o link de redefinição de senha. "
                );
            }
        } catch (error) {
            toast.error("Erro", {
                position: toast.POSITION.TOP_CENTER,
            });
            setWarning(t("authv.email_exists"));
            console.error(error);
        }
    };

    const forgetPasswordSeller = async (data: any) => {
        try {
            const response = await axios.post(
                `${baseSeller}/forgot-password`,
                data
            );
            if (response.data.result === false) {
                toast.error("Erro", {
                    position: toast.POSITION.TOP_CENTER,
                });
                setWarning(response.data.msg);
            } else {
                toast.success("Sucesso", {
                    position: toast.POSITION.TOP_CENTER,
                });
                setWarning(
                    "Verifique sua caixa de entrada para o link de redefinição de senha. "
                );
            }
        } catch (error) {
            toast.error("Erro", {
                position: toast.POSITION.TOP_CENTER,
            });
            setWarning(t("authv.email_exists"));
            console.error(error);
        }
    };

    const forgetPasswordEngineer = async (data: any) => {
        try {
            const response = await axios.post(
                `${baseEngineer}/request-password-reset`,
                {
                    data,
                    config: axiosConfig(),
                }
            );
            if (response.data.result === false) {
                toast.error("Erro", {
                    position: toast.POSITION.TOP_CENTER,
                });
                setWarning(response.data.msg);
            } else {
                toast.success("Sucesso", {
                    position: toast.POSITION.TOP_CENTER,
                });
                setWarning(
                    "Verifique sua caixa de entrada para o link de redefinição de senha. "
                );
            }
        } catch (error) {
            toast.error("Erro", {
                position: toast.POSITION.TOP_CENTER,
            });
            setWarning(t("authv.email_exists"));
            console.error(error);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                login,
                loginSeller,
                loginEngineer,
                logout,
                register,
                registerSeller,
                registerEngineer,
                forgetPassword,
                forgetPasswordSeller,
                axiosConfig,
                headerConfig,
                forgetPasswordEngineer,
                me,
                registerError,
                warning,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
