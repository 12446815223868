import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, GridContainerTotal, StatusLabel } from "./style";
import "./contract.css";
import { storageServerPath } from "../../../utils/server/api";
import { useAuth } from "../../../context/AuthContext";
import NavBar from "../../../components/navbar/navbar";
import { ToastContainer } from "react-toastify";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Footer from "../../../components/footer/footer";
import { useParams } from "react-router-dom";
import { useServiceProperty } from "../../../hooks/serviceProperty";
import { AppProperty } from "../../../models/Property";

export default function Contract() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { getContractSeller, getProperty } = useServiceProperty(id);
    const { user } = useAuth();
    const [contract, setContract] = useState();
    const [file, setFile] = useState();
    const [property, setProperty] = useState<AppProperty>();

    useEffect(() => {
        getContractSeller(id).then((data: any) => {
            setContract(data.contract);
            setFile(data.file);
        });

        async function fetchProperty() {
            const data = await getProperty();
            setProperty(data);
        }

        if (!property) {
            fetchProperty();
        }
    }, [property]);

    const getStatus = () => {
        switch (property?.status_contract) {
            case "0":
                return t("pending");
            case "1":
                return t("awaiting-approval");
            case "2":
                return t("signed");
            case "3":
                return t("canceled");
            default:
                return t("pending");
        }
    };

    return (
        <Container>
            <NavBar />
            <ToastContainer />
            <Breadcrumb noHomeIcon itens={[t("property-details-contract")]} />
            <div style={{ padding: "10px 15px", width: "97%" }}>
                <StatusLabel>{t("contract-status")}: {getStatus()}</StatusLabel>
                <div
                    className="divAlertContratoButtons"
                    style={{ textAlign: "center" }}
                >
                    Atenção: Para assinar o contrato, você precisa&nbsp;
                    <a
                        href={`${storageServerPath}${file}`}
                        target="_blank"
                        rel="noreferrer"
                        download
                        className="button"
                    >
                        baixar contrato
                    </a>
                    &nbsp; e assinar no&nbsp;
                    <a
                        href="https://assinador.iti.br/assinatura/index.xhtml"
                        target="_blank"
                        rel="noreferrer"
                    >
                        gov.br
                    </a>
                    . Após assinar, envie para o e-mail
                    comercial@ecolifemeioambiente.com.br.
                </div>
                <GridContainerTotal>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: contract || "",
                        }}
                    />
                </GridContainerTotal>
            </div>
            <Footer />
        </Container>
    );
}
