import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
    width: 100%;
`;

export const ContainerMargin = styled.div`
    margin: 30px;
`;

export const ContainerTitle = styled.div`
    display: flex;
    margin: 30px;
`;

export const Title = styled.h1`
    font-family: "Josefin Bold";
    color: #000;
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
`;

export const SubTitle = styled.h1`
    font-family: "Josefin Bold";
    color: #fff;
    font-size: 16px;
    text-align: center;
`;

export const CardContainer = styled.div`
    width: 100%;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
`;

export const Column = styled.div`
    flex-direction: column;
`;

export const Row = styled.div`
    display: flex;
    margin-left: 14px;
    margin-top: 3px;
`;

export const RowPay = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const RowTotal = styled.div`
    display: flex;
`;

export const CardContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-around;
`;

export const CardImage = styled.img`
    width: 250px;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 12px;
`;

export const CardTitle = styled.h3`
    font-size: 18px;
    margin-bottom: 8px;
    font-family: "Josefin Bold";
`;

export const Price = styled.p`
    font-size: 16px;
    margin-bottom: 8px;
    font-family: "Josefin Bold";
    margin-left: -22px;
    margin-top: 10px;
`;

export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #000;
`;

export const CardDescription = styled.p`
    font-size: 14px;
    margin-bottom: 8px;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* Ajuste o número de linhas conforme necessário */
    -webkit-box-orient: vertical;

    font-family: "Josefin Sans";
    color: "#515151";
`;

export const CardQuantity = styled.span`
    font-size: 14px;
    margin-right: 8px;
    font-family: "Josefin Bold";
`;

export const MiniRetanguloComponent = styled.div`
    width: 20px;
    height: 10px;
    background-color: green;
    margin-right: 8px;
`;

export const CardPrice = styled.p`
    font-size: 16px;
    margin-bottom: 8px;
    font-family: "Josefin Bold";
`;

export const CardValue = styled.p`
    font-size: 16px;
    margin-bottom: 8px;
    font-family: "Josefin Bold";
    color: "#515151";
`;

export const RemoveButton = styled.button`
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 120px;
    height: 30px;
    justify-content: center;
    align-items: center;

    font-family: "Josefin Bold";
    font-size: 16px;
`;

export const TrashIcon = styled.span`
    padding-top: -20px;
    justify-content: center;
    align-items: center;
    height: 900px;
`;

export const Label = styled.p`
    font-family: "Josefin Sans";
    font-size: 14px;
    color: ${colors.gray};
    margin-bottom: 2px;
    margin-top: 5px;
`;

export const LabelCard = styled.p`
    font-family: "Josefin Sans";
    font-size: 14px;
    color: ${colors.gray};
    margin-bottom: 2px;
`;

export const TitleCard = styled.h1`
    font-family: "Josefin Sans";
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
    font-size: 16px;
    margin-top: 10px;
`;

export const TitlePCard = styled.h1`
    font-family: "Josefin Sans";
    font-size: 14px;
    color: red;
    margin-bottom: 2px;
    font-size: 16px;
    margin-top: 10px;
`;

export const RowIn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Card = styled.div`
    border: 1px solid #28a745; /* Cor verde para a borda */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Elevação */
    padding: 16px;
    margin: 8px;
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    border-radius: 4px;
`;
