import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Wrapper,
  Main,
  Title,
  Icon,
  List,
  ListItem,
  NavTitleContainer,
  NavTitle,
  NavSubtitle,
} from './style';
import { useAuth } from "../../context/AuthContext";

import userIcon from '../../assets/images/user-icon.png';
import boxIcon from '../../assets/images/box-icon.png';
import coinIcon from '../../assets/images/coin-icon.png';


export type ProfileSidenavPageProps = {
  title?: string;
  pageId: string;
  children?: React.ReactNode;
}
export function ProfileSidenavPage(
  props: ProfileSidenavPageProps,
) {
  return (
    <>
      {props.children}
    </>
  );
}

interface Hidden {
    portfolio?: boolean;
    profile?: boolean;
    bank?: boolean;
    order?: boolean;
}

export type ProfileSidenavProps = {
  children?: React.ReactNode;
  initialPage: string;
  hidden?: Hidden;
  onChangePage: (page: string) => void;
};

export default function ProfileSidenav(props: ProfileSidenavProps) {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(props.initialPage || "");
  const { user } = useAuth();

  const updateActivePage = (page: string) => () => {
    props.onChangePage(page);
    setActivePage(page);
  };

  useEffect(() => {
      if (activePage === "") {
          let page = "";
          React.Children.map(props.children, (child) => {
              if (page !== "") return null;
              if (React.isValidElement(child)) {
                  if (child.type === ProfileSidenavPage) {
                      page = child.props.pageId;
                      return null;
                  }
              }
              return null;
          });
          setActivePage(page);
      }
  }, [props.children, activePage]);

  return (
      <Wrapper>
          <Container>
              <Title>
                  <Icon src={userIcon} alt="User Icon" /> {t("greetings-user")}{" "}
                  {user?.fullname}!
              </Title>

              <List>
                  <ListItem
                      onClick={updateActivePage("environmental-portfolio")}
                      active={activePage === "environmental-portfolio"}
                      hidden={props?.hidden?.portfolio}
                  >
                      <Icon src={boxIcon} alt="Box Icon" />
                      <NavTitleContainer>
                          <NavTitle>{t("environmental-portfolio")}</NavTitle>
                          <NavSubtitle>
                              {t("environmental-portfolio-description")}
                          </NavSubtitle>
                      </NavTitleContainer>
                  </ListItem>

                  <ListItem
                      onClick={updateActivePage("properties")}
                      active={activePage === "properties"}
                      hidden={props?.hidden?.order}
                  >
                      <Icon src={boxIcon} alt="Box Icon" />
                      <NavTitleContainer>
                          <NavTitle>{t("my-properties")}</NavTitle>
                          <NavSubtitle>{t("All properties")}</NavSubtitle>
                      </NavTitleContainer>
                  </ListItem>

                  <ListItem
                      onClick={updateActivePage("profile")}
                      active={activePage === "profile"}
                      hidden={props?.hidden?.profile}
                  >
                      <Icon src={userIcon} alt="User Icon" />
                      <NavTitleContainer>
                          <NavTitle>{t("profile")}</NavTitle>
                          <NavSubtitle>{t("profile-description")}</NavSubtitle>
                      </NavTitleContainer>
                  </ListItem>

                  <ListItem
                      onClick={updateActivePage("bank-data")}
                      active={activePage === "bank-data"}
                      hidden={props?.hidden?.bank}
                  >
                      <Icon src={coinIcon} alt="Coin Icon" />
                      <NavTitleContainer>
                          <NavTitle>{t("bank-data")}</NavTitle>
                          <NavSubtitle>
                              {t("bank-data-description")}
                          </NavSubtitle>
                      </NavTitleContainer>
                  </ListItem>
              </List>
          </Container>

          <Main>
              {React.Children.map(props.children, (child) => {
                  if (React.isValidElement(child)) {
                      if (child.type === ProfileSidenavPage) {
                          if (child.props.pageId === activePage) {
                              return (
                                  <>
                                      <Title style={{ marginLeft: 0 }}>
                                          {child.props.title}
                                      </Title>

                                      <div style={{ marginBottom: 40 }}>
                                          {child}
                                      </div>
                                  </>
                              );
                          }
                      }
                  }
                  return null;
              })}
          </Main>
      </Wrapper>
  );
}
