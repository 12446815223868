import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, GridContainerTotal } from "./style";
import { useServiceUser } from "../../../hooks/serviceUser";
import "./contract.css";
import { storageServerPath } from "../../../utils/server/api";
import { useAuth } from "../../../context/AuthContext";

export default function MySpaceEngineerContracts() {
    const { t } = useTranslation();
    const { getContract } = useServiceUser();
    const [contract, setContract] = useState();
    const [file, setFile] = useState();
    const { user } = useAuth();

    useEffect(() => {
        getContract().then((data: any) => {
            setContract(data.contract);
            setFile(data.file);
        });
    }, []);

    const getStatus = () => {
        switch (user?.status_contract) {
            case "0":
                return t("pending");
            case "1":
                return t("awaiting-approval");
            case "2":
                return t("signed");
            case "3":
                return t("canceled");
            default:
                return t("pending");
        }
    };

    return (
        <Container>
            <div style={{ fontSize: "16px" }}>{getStatus()}</div>
            <div
                className="divAlertContratoButtons"
                style={{ textAlign: "center" }}
            >
                Atenção: Para assinar o contrato, você precisa&nbsp;
                <a
                    href={`${storageServerPath}${file}`}
                    target="_blank"
                    rel="noreferrer"
                    download
                    className="button"
                >
                    baixar contrato
                </a>
                &nbsp; e assinar no&nbsp;
                <a
                    href="https://assinador.iti.br/assinatura/index.xhtml"
                    target="_blank"
                    rel="noreferrer"
                >
                    gov.br
                </a>
                . Após assinar, envie para o e-mail
                comercial@ecolifemeioambiente.com.br.
            </div>

            <GridContainerTotal>
                <div
                    dangerouslySetInnerHTML={{
                        __html: contract || "",
                    }}
                />
            </GridContainerTotal>
        </Container>
    );
}
