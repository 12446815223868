import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Footer from "../../../components/footer/footer";
import NavBar from "../../../components/navbar/navbar";
import { useServiceProperty } from "../../../hooks/serviceProperty";
import { useServiceShoppingCart } from "../../../hooks/serviceShoppingCart";
import { AppProperty } from "../../../models/Property";
import {
    BannerContainer,
    Container,
    ContentContainer,
    HeaderContainer,
    HeaderTable,
    Main,
    MetaContainer,
    MetaItem,
    Title,
} from "./style";
import { storageServerPath } from "../../../utils/server/api";
import Gallery from "../../../components/gallery/gallery";
import { useAuth } from "../../../context/AuthContext";
import ModalConfirmation from "../../../components/alerts/modalConfirmation";
import ModalConfirmation2 from "../../../components/alerts/modalConfirmation";
import { toast, ToastContainer } from "react-toastify";

import mapIcon from "../../../assets/images/map-icon.png";

export default function DetailsProperty() {
    const { id } = useParams();
    const { t } = useTranslation();
    const { getProperty, removeProperty, engineerConnect, engineerDisconnect } =
        useServiceProperty(id);
    const { createOrder } = useServiceShoppingCart(id);
    const [property, setProperty] = useState<AppProperty>();
    const { user } = useAuth();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalAddToCart, setOpenModalAddToCart] = useState(false);
    const navigate = useNavigate();
    const lng = localStorage.getItem("language");

    useEffect(() => {
        async function fetchProperty() {
            const data = await getProperty();
            setProperty(data);
        }

        function analisePage() {
            if (
                user?.user_type === "engineer" &&
                property?.engineer_connect !== null &&
                property?.engineer_connect !== user?.id
            ) {
                navigate("/news");
            }
        }

        if (!property) {
            fetchProperty();
        }

        if (property) {
            analisePage();
        }
    }, [property]);

    const openMap = () => {
        navigate(`/satelite?lat=${property?.lat}&lng=${property?.lng}`);
    };

    const openMapBiomas = () => {
        navigate(`/mapBiomas?lat=${property?.lat}&lng=${property?.lng}`);
    };

    const alterOpenModalDelete = (value: boolean, status: boolean) => {
        setOpenModalDelete(value);

        if (status === true) {
            removeProperty().then(() => {
                navigate("/mySpaceSeller/environmental-portfolio");
            });
        }
    };

    const engineerConnectButton = async () => {
        await engineerConnect().then((data: any) => {
            toast.success(t("successfully"), {
                position: "top-right",
            });
            updateData();
        });
    };

    const engineerDisconnectButton = async () => {
        await engineerDisconnect().then((data: any) => {
            toast.success(t("successfully"), {
                position: "top-right",
            });
            updateData();
        });
    };

    const updateData = async () => {
        const data = await getProperty();
        setProperty(data);
    };

    const Nl2br = ({ text }: { text: string }) => {
        const html = text.replace(/\n/g, "<br />");
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    };

    const addToCart = (value: boolean, status: boolean) => {
        setOpenModalAddToCart(value);

        if (status === true) {
            createOrder().then(() => {
                navigate("/order");
            });
        }
    };

    const setOpenContract = () => {
        navigate(`/contract/${id}`);
    };

    return (
        <Container>
            <NavBar />
            <ToastContainer />
            <Breadcrumb noHomeIcon itens={[t("property-details")]} />

            {property && (
                <Main>
                    <BannerContainer>
                        <Gallery
                            images={property?.file_images?.split(";")}
                            url={storageServerPath}
                        />
                    </BannerContainer>
                    <HeaderContainer>
                        <Title>
                            {lng === "en" ? property.name_en : property.name}
                        </Title>
                        <HeaderTable>
                            {property.status !== "catalog" && (
                                <div>
                                    <strong>{t("price")}</strong>{" "}
                                    <p>{property.cost_formatted}</p>
                                </div>
                            )}

                            {/*<div><strong>{t("carbon-credit")}</strong> <p>{property}</p></div>*/}

                            {/* <div>
                              <strong>{t("address")}</strong>{" "}
                              <p>{property.address}</p>
                          </div> */}
                            <div>
                                <strong>{t("Latitude")}</strong>{" "}
                                <p>{property.latDms}</p>
                            </div>
                            <div>
                                <strong>{t("Longitude")}</strong>{" "}
                                <p>{property.lngDms}</p>
                            </div>

                            <div>
                                <strong>{t("hectares")}</strong>{" "}
                                <p>{property.acre}</p>
                            </div>
                            {property.status !== "catalog" && (
                                <div>
                                    <strong>{t("deed-number")}</strong>{" "}
                                    <p>{property.deed_number}</p>
                                </div>
                            )}
                            {property.status !== "catalog" && (
                                <div>
                                    <strong>{t("number-doc")}</strong>{" "}
                                    <p>{property.cib_nirf_number}</p>
                                </div>
                            )}
                            <div>
                                <strong>{t("vegetation")}</strong>{" "}
                                <p>{t(`vegetation-${property.vegetation}`)}</p>
                            </div>
                            <div>
                                <strong>{t("carbon_credits_available")}</strong>{" "}
                                <p>
                                    {property.carbon_credits_available > 0
                                        ? property.carbon_credits_available
                                        : 0}
                                </p>
                            </div>
                        </HeaderTable>
                    </HeaderContainer>

                    <MetaContainer>
                        <MetaItem onClick={openMap}>
                            <img src={mapIcon} alt="Map Icon" />
                            <p>{t("map-track")}</p>
                        </MetaItem>
                        <MetaItem onClick={openMapBiomas}>
                            <img src={mapIcon} alt="Map Icon" />
                            <p>{t("MapBiomas")}</p>
                        </MetaItem>
                        {property.status !== "catalog" && (
                            <>
                                <MetaItem
                                    onClick={() =>
                                        window.open(
                                            storageServerPath +
                                                property.file_cib
                                        )
                                    }
                                >
                                    <p>{t("cib-file")}</p>
                                </MetaItem>
                                <MetaItem
                                    onClick={() =>
                                        window.open(
                                            storageServerPath +
                                                property.file_deed_number
                                        )
                                    }
                                >
                                    <p>{t("deed-file")}</p>
                                </MetaItem>
                                <MetaItem
                                    onClick={() =>
                                        window.open(
                                            storageServerPath +
                                                property.file_proof_of_residence
                                        )
                                    }
                                >
                                    <p>{t("proof-residence")}</p>
                                </MetaItem>
                            </>
                        )}
                        <>
                            {((user?.user_type === "seller" &&
                                property?.user_id === user?.id &&
                                property?.engineer_update !== 1) ||
                                (user?.user_type === "engineer" &&
                                    property?.engineer_connect ===
                                        user?.id)) && (
                                <MetaItem
                                    style={{ backgroundColor: "blue" }}
                                    onClick={() =>
                                        navigate(
                                            `/updateProperty/${property.id}`
                                        )
                                    }
                                >
                                    <p
                                        style={{
                                            color: "#fff",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("update")}
                                    </p>
                                </MetaItem>
                            )}
                            {user?.user_type === "seller" &&
                                property?.user_id === user?.id && (
                                    <>
                                        <MetaItem
                                            style={{ backgroundColor: "red" }}
                                            onClick={() => {
                                                setOpenModalDelete(true);
                                            }}
                                        >
                                            <p
                                                style={{
                                                    color: "#fff",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("delete")}
                                            </p>
                                        </MetaItem>
                                        <MetaItem
                                            style={{ backgroundColor: "gray" }}
                                            onClick={() => {
                                                setOpenContract();
                                            }}
                                        >
                                            <p
                                                style={{
                                                    color: "#000",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("contracts")}
                                            </p>
                                        </MetaItem>
                                    </>
                                )}
                            {user?.user_type === "engineer" &&
                                (property?.engineer_connect === undefined ||
                                    property?.engineer_connect === null) && (
                                    <MetaItem
                                        style={{ backgroundColor: "orange" }}
                                        onClick={() => {
                                            engineerConnectButton();
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: "#fff",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {t("bind-property")}
                                        </p>
                                    </MetaItem>
                                )}
                            {user?.user_type === "engineer" &&
                                property?.engineer_connect === user?.id && (
                                    <MetaItem
                                        style={{ backgroundColor: "#aa6e00" }}
                                        onClick={() => {
                                            engineerDisconnectButton();
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: "#fff",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {t("disassociate-property")}
                                        </p>
                                    </MetaItem>
                                )}
                            {user?.user_type === "buyer" &&
                                user?.trial_period === false &&
                                property.carbon_credits_available > 0 &&
                                property.status === "active" && (
                                    <MetaItem
                                        style={{ backgroundColor: "green" }}
                                        onClick={() => {
                                            setOpenModalAddToCart(true);
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: "#fff",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {t("add-to-cart")}
                                        </p>
                                    </MetaItem>
                                )}
                            {user?.user_type === "buyer" &&
                                user?.trial_period === false &&
                                property.carbon_credits_available <= 0 && (
                                    <MetaItem
                                        style={{ backgroundColor: "gray" }}
                                    >
                                        <p
                                            style={{
                                                color: "#fff",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {t("unavailable")}
                                        </p>
                                    </MetaItem>
                                )}
                            {user?.trial_period === true && (
                                <MetaItem style={{ backgroundColor: "orange" }}>
                                    <p
                                        style={{
                                            color: "#fff",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("trial_period")} {user?.test_time}
                                    </p>
                                </MetaItem>
                            )}
                        </>
                    </MetaContainer>
                    <ModalConfirmation
                        show={openModalDelete}
                        alterOpenModalDelete={alterOpenModalDelete}
                        message={t("delete-confirmation")}
                    />
                    <ModalConfirmation2
                        show={openModalAddToCart}
                        alterOpenModalDelete={addToCart}
                        message={t("can-add-to-cart")}
                    />
                    <ContentContainer>
                        <Title>
                            {lng === "en" ? property.name_en : property.name}
                        </Title>

                        <div>
                            <Title style={{ textDecoration: "underline" }}>
                                {<Nl2br text={t("details")} />}
                            </Title>

                            <p>
                                {lng === "en"
                                    ? property.description_en
                                    : property.description}
                            </p>
                        </div>
                    </ContentContainer>
                </Main>
            )}

            <Footer />
        </Container>
    );
}
