import { useState } from "react";
import {
    ButtonEnter,
    Container,
    Content,
    LinhaVertical,
    Space,
    TitleButtonEnter,
    TitleInput,
    Title,
    MarginBottom,
    ErrorText,
    Img,
    DivImg,
    ContainerMargin,
} from "./style";
import { BeatLoader } from "react-spinners";
import {
    Formik,
    Field,
    Form,
    FormikHelpers,
    FieldInputProps,
    ErrorMessage,
} from "formik";
import { MdOutlineEmail } from "react-icons/md";
import { useAuth } from "../../context/AuthContext";
import InputWithIcon from "../../components/input/input";
import logo from "../../assets/images/new-logo.png";
import { Center } from "@chakra-ui/react";

import { ToastContainer } from "react-toastify";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";

export default function ForgetPassword() {
    const { forgetPassword, warning, forgetPasswordSeller } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const userType = localStorage.getItem("userType");
    const { t } = useTranslation();

    const initialValues = {
        email: "",
    };

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        try {
            setIsLoading(true);
            if (userType === "cliente") {
                await forgetPassword(values);
                setIsLoading(false);
            } else {
                await forgetPasswordSeller(values);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Erro:", (error as Error).message);
        }
    };
    return (
        <Container>
            <NavBar />
            <Content>
                <Space>
                    <DivImg>
                        <Img src={logo} alt="Minha Imagem" />
                    </DivImg>
                </Space>
                <LinhaVertical />
                <Space>
                    <Title>{t("recover-password")}</Title>
                    <ContainerMargin>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <TitleInput>{t("email")}</TitleInput>
                                <Field
                                    type="text"
                                    name="email"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <InputWithIcon
                                            icon={<MdOutlineEmail />}
                                            iconSize="23px"
                                            field={field}
                                            placeholder={t(
                                                "type-recover-email"
                                            )}
                                        />
                                    )}
                                />
                                <ErrorText>
                                    <ErrorMessage name="email" />
                                </ErrorText>

                                <MarginBottom />

                                {warning && <ErrorText>{warning}</ErrorText>}
                                <MarginBottom />
                                <Center>
                                    <ButtonEnter type="submit">
                                        {isLoading ? (
                                            <BeatLoader
                                                className="spinner"
                                                size={8}
                                                color={"#fff"}
                                            />
                                        ) : (
                                            <TitleButtonEnter>
                                                {t("button-send")}
                                            </TitleButtonEnter>
                                        )}
                                    </ButtonEnter>
                                </Center>
                            </Form>
                        </Formik>
                    </ContainerMargin>
                </Space>
            </Content>
            <ToastContainer />
            <Footer />
        </Container>
    );
}
