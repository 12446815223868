import { formatCurrency } from "../utils/formatters";

type Property = {
    id: number;
    name: string;
    name_en: string;
    deed_number: number;
    acre: string;
    cib_nirf_number: string;
    cost: string;
    description: string;
    description_en: string;
    vegetation: string;
    address: string;
    user_id: number;
    file_deed_number: string;
    file_cib: string;
    file_proof_of_residence: string;
    file_images: string;
    lat: any;
    lng: any;
    latDms: any;
    lngDms: any;
    engineer_update: any;
    engineer_connect: any;
    carbon_credits: any;
    carbon_credits_available: any;
    status: string;
    status_contract: any;
};

type AppProperty = Property & {
    cost_formatted: string;
};

export function parseProperty(property: Property): AppProperty {
    return {
        id: property.id,
        name: property.name,
        name_en: property.name_en,
        deed_number: property.deed_number,
        acre: property.acre,
        cib_nirf_number: property.cib_nirf_number,
        cost: property.cost,
        description: property.description,
        description_en: property.description_en,
        vegetation: property.vegetation,
        address: property.address,
        user_id: property.user_id,
        file_deed_number: property.file_deed_number,
        file_cib: property.file_cib,
        file_proof_of_residence: property.file_proof_of_residence,
        file_images: property.file_images,
        cost_formatted: formatCurrency(property.cost),
        lat: property.lat,
        lng: property.lng,
        latDms: property.latDms,
        lngDms: property.lngDms,
        engineer_update: property.engineer_update,
        engineer_connect: property.engineer_connect,
        carbon_credits: property.carbon_credits,
        carbon_credits_available: property.carbon_credits_available,
        status: property.status,
        status_contract: property.status_contract,
    };
}

export function parsePropertyList(properties: Property[]): AppProperty[] {
    return properties.map((property) => parseProperty(property));
}

export { Property, AppProperty };
