import React from "react";

import {
    CompanyInfo,
    FooterContainer,
    FooterContent,
    SubTitle,
    Title,
    Copyright,
    FooterImage,
    MenuFooter,
    Line,
} from "./style";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ic_logo from "../../assets/images/new-logo.png";
import ic_whats from "../../assets/images/whatsapp.png";
import ic_telephone from "../../assets/images/telephone.png";
import ic_mail from "../../assets/images/mail.png";

const Footer: React.FC<any> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <FooterContainer>
            <FooterContent>
                <div>
                    <img
                        src={ic_logo}
                        alt="logo"
                        style={{
                            width: "230px",
                        }}
                    />
                </div>
                <CompanyInfo>
                    <Title>{t("about-us")}</Title>
                    <SubTitle>{t("description-about-us")}</SubTitle>
                </CompanyInfo>
                <Line />
                <CompanyInfo>
                    <MenuFooter>
                        <ul>
                            <li>
                                <span onClick={() => navigate("/homeBuy")}>
                                    {t("home")}
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => navigate("/pronouncement")}
                                >
                                    {t("Pronouncements")}
                                </span>
                            </li>
                            <li>
                                <span onClick={() => navigate("/news")}>
                                    {t("news")}
                                </span>
                            </li>
                            <li>
                                <span onClick={() => navigate("/mySpace")}>
                                    {t("my-space")}
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => {
                                        window.open(
                                            "https://iniciativaverde.org.br/calculadora"
                                        );
                                    }}
                                >
                                    {t("calculate-co2")}
                                </span>
                            </li>
                        </ul>
                    </MenuFooter>
                </CompanyInfo>
                <Line />
                <CompanyInfo>
                    <Title>{t("contact-us")}</Title>
                    <SubTitle>
                        <FooterImage src={ic_whats} />
                        &nbsp;Whatsapp:{" "}
                        <a
                            href="https://api.whatsapp.com/send?phone=5561990227396"
                            target="_blank"
                            rel="noreferrer"
                        >
                            (61) 99022-7396
                        </a>
                    </SubTitle>
                    <SubTitle>
                        <FooterImage src={ic_telephone} />
                        &nbsp;{t("contact-us")}:
                        <a
                            href="tel:+556135472977"
                            target="_blank"
                            rel="noreferrer"
                        >
                            (61) 3547-2977
                        </a>
                    </SubTitle>
                    <SubTitle>
                        <FooterImage src={ic_mail} />
                        &nbsp;{t("email")}:{" "}
                        <a
                            href="mailto:comercial@ecolifemeioambiente.com.br"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: "12px" }}
                        >
                            comercial@ecolifemeioambiente.com.br
                        </a>
                    </SubTitle>
                </CompanyInfo>
            </FooterContent>
            <Copyright>
                <p>
                    Copyright © 2024 ECOLIFE – Tecnologia e Meio Ambiente.&nbsp;
                    {t("developed-by")}: Ecolife
                </p>
            </Copyright>
        </FooterContainer>
    );
};

export default Footer;
